import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Register.css';

const Register = ({ language }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false); // Controle de visibilidade da senha
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Controle de visibilidade de confirmação de senha
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [emailMatchError, setEmailMatchError] = useState('');
    const [passwordLengthError, setPasswordLengthError] = useState('');
    const [usernameLengthError, setUsernameLengthError] = useState('');
    const [showTips, setShowTips] = useState(false); // Controle para mostrar dicas
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');
        setFieldErrors({});
        setPasswordMatchError('');
        setEmailMatchError('');
        setPasswordLengthError('');
        setUsernameLengthError('');

        if (email !== confirmEmail) {
            setEmailMatchError(
                language === 'pt' ? 'Os e-mails não coincidem.' :
                language === 'es' ? 'Los correos electrónicos no coinciden.' :
                'Emails do not match.'
            );
            return;
        }

        if (username.length < 5 || username.length > 20) {
            setUsernameLengthError(
                language === 'pt' ? 'O nome de usuário deve ter entre 5 e 20 caracteres.' :
                language === 'es' ? 'El nombre de usuario debe tener entre 5 y 20 caracteres.' :
                'Username must be between 5 and 20 characters long.'
            );
            return;
        }

        if (password.length < 8) {
            setPasswordLengthError(
                language === 'pt' ? 'A senha deve ter pelo menos 8 caracteres.' :
                language === 'es' ? 'La contraseña debe tener al menos 8 caracteres.' :
                'Password must be at least 8 characters long.'
            );
            return;
        }

        if (!password || password !== confirmPassword) {
            setPasswordMatchError(
                language === 'pt' ? 'Senhas não coincidem.' :
                language === 'es' ? 'Las contraseñas no coinciden.' :
                'Passwords do not match.'
            );
            return;
        }

        try {
            const response = await axios.post('https://2824cdfd-4984-4a62-b8b0-57e1a82ae482-00-2ufqu5w2gizqz.worf.replit.dev:8000/auth/register', {
                username,
                email,
                password
            });

            if (response.data.message === 'Usuário registrado com sucesso!') {
                setSuccessMessage(
                    language === 'pt' ? 'Parabéns, seu usuário foi registrado com sucesso!' :
                    language === 'es' ? '¡Felicidades, su usuario ha sido registrado con éxito!' :
                    'Congratulations, your user has been successfully registered!'
                );

                setTimeout(() => {
                    navigate('/login');
                }, 2000);  // Redireciona para a página de login após 2 segundos
            } else {
                setErrorMessage(
                    language === 'pt' ? 'Erro ao registrar' :
                    language === 'es' ? 'Error al registrar' :
                    'Error registering'
                );
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                setErrorMessage(error.response.data.detail);
                if (error.response.data.errors) {
                    setFieldErrors(error.response.data.errors);
                }
            } else {
                setErrorMessage(
                    language === 'pt' ? 'Houve um erro ao registrar!' :
                    language === 'es' ? '¡Hubo un error al registrar!' :
                    'There was an error registering!'
                );
            }
        }
    };

    const toggleTips = () => {
        setShowTips(!showTips);
    };

    const handlePasswordChange = (e) => {
        const pwd = e.target.value;
        setPassword(pwd);
        setPasswordMatchError('');
        if (pwd.length < 8) {
            setPasswordLengthError(
                language === 'pt' ? 'A senha deve ter pelo menos 8 caracteres.' :
                language === 'es' ? 'La contraseña debe tener al menos 8 caracteres.' :
                'Password must be at least 8 characters long.'
            );
        } else {
            setPasswordLengthError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (e.target.value !== password) {
            setPasswordMatchError(
                language === 'pt' ? 'Senhas não coincidem.' :
                language === 'es' ? 'Las contraseñas no coinciden.' :
                'Passwords do not match.'
            );
        } else {
            setPasswordMatchError('');
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailMatchError('');
    };

    const handleConfirmEmailChange = (e) => {
        setConfirmEmail(e.target.value);
        if (e.target.value !== email) {
            setEmailMatchError(
                language === 'pt' ? 'Os e-mails não coincidem.' :
                language === 'es' ? 'Los correos electrónicos no coinciden.' :
                'Emails do not match.'
            );
        } else {
            setEmailMatchError('');
        }
    };

    const handleUsernameChange = (e) => {
        const uname = e.target.value;
        setUsername(uname);
        if (uname.length < 5 || username.length > 20) {
            setUsernameLengthError(
                language === 'pt' ? 'O nome de usuário deve ter entre 5 e 20 caracteres.' :
                language === 'es' ? 'El nombre de usuario debe tener entre 5 y 20 caracteres.' :
                'Username must be between 5 and 20 characters long.'
            );
        } else {
            setUsernameLengthError('');
        }
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <button className="back-button" onClick={() => navigate('/login')}>
                    <i className="fas fa-arrow-left"></i>
                </button>
                <h2>{language === 'pt' ? 'Registrar' : language === 'es' ? 'Registrar' : 'Register'}</h2>
                {successMessage && <p className="success-message" style={{ color: 'green' }}>{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Usuário' : language === 'es' ? 'Usuario' : 'Username'}</label>
                        <input
                            type="text"
                            value={username}
                            onChange={handleUsernameChange}
                            required
                        />
                        {usernameLengthError && <p className="error-message">{usernameLengthError}</p>}
                        {fieldErrors.username && <p className="error-message">{fieldErrors.username}</p>}
                    </div>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Email' : language === 'es' ? 'Correo Electrónico' : 'Email'}</label>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                        />
                        {fieldErrors.email && <p className="error-message">{fieldErrors.email}</p>}
                    </div>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Confirmar Email' : language === 'es' ? 'Confirmar Correo Electrónico' : 'Confirm Email'}</label>
                        <input
                            type="email"
                            value={confirmEmail}
                            onChange={handleConfirmEmailChange}
                            required
                        />
                        {emailMatchError && <p className="error-message">{emailMatchError}</p>}
                    </div>
                    <div className="input-group password-input">
                        <label>{language === 'pt' ? 'Senha' : language === 'es' ? 'Contraseña' : 'Password'}</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <span
                            className="password-toggle-icon"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                                                       {showPassword ? '👓' : '🕶️'} {/* Ícones de visibilidade */}
                        </span>
                    </div>
                    {passwordLengthError && <p className="error-message">{passwordLengthError}</p>}
                    <div className="input-group password-input">
                        <label>{language === 'pt' ? 'Confirmar Senha' : language === 'es' ? 'Confirmar contraseña' : 'Confirm Password'}</label>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                        <span
                            className="password-toggle-icon"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? '👓' : '🕶️'} {/* Ícones de visibilidade */}
                        </span>
                    </div>
                    {passwordMatchError && <p className="error-message">{passwordMatchError}</p>}
                    <button
                        type="submit"
                        className="register-button"
                        disabled={
                            password !== confirmPassword ||
                            password.length < 8 ||
                            email !== confirmEmail ||
                            username.length < 5
                        }
                    >
                        {language === 'pt' ? 'Registrar' : language === 'es' ? 'Registrar' : 'Register'}
                    </button>
                </form>
                <p className="tips-toggle" onClick={toggleTips}>
                    {language === 'pt' ? 'Clique aqui para dicas de como fazer um bom registro' : language === 'es' ? 'Haga clic aquí para obtener consejos sobre cómo hacer un buen registro' : 'Click here for tips on how to make a good registration'}
                </p>
                {showTips && (
                    <div className="tips-content">
                        <p><strong>{language === 'pt' ? 'Dicas de Registro:' : language === 'es' ? 'Consejos de Registro:' : 'Registration Tips:'}</strong></p>
                        <ul>
                            <li>{language === 'pt' ? 'O nome de usuário deve ter entre 5 e 20 caracteres, além de conter letras, números e underscores.' : language === 'es' ? 'El nombre de usuario debe tener entre 5 y 20 caracteres e incluir letras, números y guiones bajos.' : 'Username should be between 5 and 20 characters long, and include letters, numbers, and underscores.'}</li>
                            <li>{language === 'pt' ? 'Use um endereço de email válido.' : language === 'es' ? 'Utilice una dirección de correo electrónico válida.' : 'Use a valid email address.'}</li>
                            <li>{language === 'pt' ? 'A senha deve ter pelo menos 8 caracteres, incluindo letras, números e um caractere especial (como o "@", dentre outros).' : language === 'es' ? 'La contraseña debe tener al menos 8 caracteres, incluyendo letras, números y un carácter especial (como "@", entre otros).' : 'Password should be at least 8 characters long, including letters, numbers, and a special character (like "@", among others).'}</li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Register;

