import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css'; // Mantenha o arquivo CSS

const Login = ({ language }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // Novo estado para visibilidade da senha
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        if (!password) {
            setErrorMessage(language === 'pt' ? 'A senha não pode estar vazia.' : language === 'es' ? 'La contraseña no puede estar vacía.' : 'Password cannot be empty.');
            return;
        }

        try {
            const response = await axios.post('https://2824cdfd-4984-4a62-b8b0-57e1a82ae482-00-2ufqu5w2gizqz.worf.replit.dev:8000/auth/login', {
                email,
                password
            });

            if (response.data.message === 'Login com sucesso!') {
                const user = {
                    name: response.data.name,
                    occurrences: response.data.occurrences,
                    id_cliente: response.data.id_cliente,
                    email: email
                };
                localStorage.setItem('user', JSON.stringify(user));
                setSuccessMessage(language === 'pt' ? 'Login efetuado com sucesso!' : language === 'es' ? '¡Inicio de sesión exitoso!' : 'Login successful!');
                setTimeout(() => {
                    navigate('/welcome');
                }, 1000);
            } else {
                setErrorMessage(language === 'pt' ? 'Credenciais inválidas' : language === 'es' ? 'Credenciales inválidas' : 'Invalid credentials');
            }
        } catch (error) {
            setErrorMessage(language === 'pt' ? 'Houve um erro ao efetuar login!' : language === 'es' ? '¡Hubo un error al iniciar sesión!' : 'There was an error logging in!');
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>{language === 'pt' ? 'Login' : language === 'es' ? 'Iniciar sesión' : 'Login'}</h2>
                {successMessage && <p className="success-message">{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Email' : language === 'es' ? 'Correo electrónico' : 'Email'}</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group password-input">
                        <label>{language === 'pt' ? 'Senha' : language === 'es' ? 'Contraseña' : 'Password'}</label>
                        <input
                            type={showPassword ? 'text' : 'password'} // Alterna entre texto e asteriscos
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <span
                            className="password-toggle-icon"
                            onClick={() => setShowPassword(!showPassword)} // Alterna o estado de exibição da senha
                        >
                            {showPassword ? '👓' : '🕶️'} {/* Ícones de olho aberto e fechado */}
                        </span>
                    </div>
                    <button type="submit" className="login-button">
                        {language === 'pt' ? 'Logar' : language === 'es' ? 'Iniciar sesión' : 'Login'}
                    </button>
                </form>
                <div className="signup-link">
                    {language === 'pt' ? 'Não tem uma conta?' : language === 'es' ? '¿No tienes una cuenta?' : 'Don’t have an account?'} <a href="/register">{language === 'pt' ? 'Registre-se aqui!' : language === 'es' ? '¡Regístrate aquí!' : 'Sign up here!'}</a>
                </div>
                <div className="forgot-password">
                    <a href="/forgot-password">{language === 'pt' ? 'Esqueci minha senha' : language === 'es' ? 'Olvidé mi contraseña' : 'Forgot password'}</a>
                </div>
            </div>
        </div>
    );
};

export default Login;
